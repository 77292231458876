import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import Breadcrumb from '../Breadcrumb'
import { Button } from 'components/Button'
import { themes } from '../../global'
import './BlogPost.scss'

const BlogPost = ({
  title,
  author,
  timeToRead,
  date,
  headerImage,
  body,
  pathname,
}) => {
  const colorTheme = themes['lace']
  return (
    <div className='iliad__blogpost--container'>
      <div className='iliad__blogpost--cell-one'>
        {headerImage && (
          <GatsbyImage
            className='iliad__blogpost--header-image'
            image={headerImage}
            alt=''
            style={{ width: '100%', height: '400px' }}
            imgStyle={{ height: '100%' }}
            objectFit='cover'
          />
        )}
      </div>
      <div className='iliad__blogpost--cell-two'>
        <header className='iliad__blogpost--header'>
          <div className='iliad__blogpost--subgrid'>
            <div className='iliad__blogpost--info'>
              <div>
                <div className='iliad__blogpost--author'>
                  <span className='iliad__display--no-mobile'>— </span>
                  <span>{author}</span>
                </div>
                <div className='iliad__blogpost--aux-info'>
                  <time className='iliad__blogpost--date'>{date}</time>
                  <span className='iliad__blogpost--readtime'>
                    Est. Read Time: <br />
                    {timeToRead} min.
                  </span>
                </div>
              </div>
            </div>
            <div className='iliad__blogpost--title'>
              <Breadcrumb
                parentName='News'
                parentURL='/news/'
                pathname={pathname}
                title={title}
              />
              <h1>{title}</h1>
            </div>
          </div>
        </header>
      </div>
      <div className='iliad__blogpost--cell-three'>
        <hr className='iliad__display--only-mobile' />
        <section
          className='iliad__blogpost--body'
          dangerouslySetInnerHTML={{ __html: body }}
        />
        <section className='iliad__blogpost--cta'>
          <hr />
          <h2>For more information…</h2>
          <p>
            At <strong>Iliad Epic Grow</strong>, We enjoy keeping enthusiasts,
            vendors, and community members informed about our work in the
            cannabis industry.
          </p>
          <p>
            Please feel free to contact us at{' '}
            <a href='mailto:info@iliadgrow.com'>info@iliadgrow.com</a> with any
            questions, concerns, or suggestions.
          </p>
          <Button
            buttonText='Contact Us'
            buttonAction='scrollContact'
            colorTheme={colorTheme}
          />
        </section>
      </div>
    </div>
  )
}

export default BlogPost
